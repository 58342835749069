<template>
  <div class="invite">
    <div class="invite-bg">
      <img src="@/assets/img/invite/openbeta-login_bg.png" class="invite-bg-img" />
    </div>
    <div class="content">
      <div class="invite-login">
        <InviteLogin
          :inviterMobile="inviterMobile"
          :invitationMethod="invitationMethod"
          :isFormLogin="true"
        ></InviteLogin>
      </div>
      <!-- <div class="activity-rule">
        <ActivityRule></ActivityRule>
      </div> -->
    </div>
  </div>
</template>
<script>
import Vue from "vue";
import InviteLogin from "./components/invite-login.vue";

// import ActivityRule from './components/activity-rule.vue';
export default Vue.extend({
  components: { InviteLogin },
  data() {
    return {
      inviterMobile: "",
      invitationMethod: "0",
    };
  },
  mounted() {
    this.$nextTick(() => {
      localStorage.removeItem("completeService");
      this.$store.commit("SET_COMPLETE_SERVICE", "false");
    });

    document.title = "公测赢福利  纵享三重礼";
    if ((this.$store.getters.inviteToken ?? "") !== "") {
      this.$router.push({
        path: `/openBetaShareEwm?inviterMobile=${this.$store.getters.currentLoginMobile}`,
      });
    }
    // if (
    //   (this.$route.query?.token ?? "") !== "" &&
    //   (this.$route.query?.currentLoginMobile ?? "") !== ""
    // ) {
    //   this.$store.commit("SET_INVITE_TOKEN", this.$route.query?.token ?? "");
    //   localStorage.setItem("inviteToken", this.$route.query?.token ?? "");
    //   this.$store.commit(
    //     "SET_CURRENT_LOGIN_MOBILE",
    //     this.$route.query?.currentLoginMobile ?? ""
    //   );
    //   localStorage.setItem(
    //     "currentLoginMobile",
    //     this.$route.query?.currentLoginMobile ?? ""
    //   );
    //   let info = `inviterMobile,${this.$route.query?.currentLoginMobile}_invitationMethod,1`;
    //   this.$router.replace({
    //     path: `/openBetaShare?info=${info}`,
    //   });
    // } else if (
    //   (this.$store.getters.inviteToken ?? "") !== "" &&
    //   this.$store.getters.currentLoginMobile !== this.$route.query?.inviterMobile
    // ) {
    //   localStorage.removeItem("inviteToken");
    //   localStorage.removeItem("currentLoginMobile");
    //   this.$store.commit("SET_INVITE_TOKEN", "");
    //   this.$store.commit("SET_CURRENT_LOGIN_MOBILE", "");
    //   if (this.$route.query?.inviterMobile) {
    //     this.inviterMobile = this.$route.query?.inviterMobile ?? "";
    //   }
    //   if (this.$route.query?.invitationMethod) {
    //     this.invitationMethod = this.$route.query?.invitationMethod ?? "0";
    //   }
    // } else if ((this.$store.getters.inviteToken ?? "") !== "") {
    //   let info = `inviterMobile,${this.$store.getters.currentLoginMobile}_invitationMethod,1`;
    //   this.$router.replace({
    //     path: `/openBetaShare?inviterMobile=${info}`,
    //   });
    // } else {
    //   if (this.$route.query?.inviterMobile) {
    //     this.inviterMobile = this.$route.query?.inviterMobile ?? "";
    //   }
    //   if (this.$route.query?.invitationMethod) {
    //     this.invitationMethod = this.$route.query?.invitationMethod ?? "0";
    //   }
    // }
  },
});
</script>
<style lang="less" scoped>
.invite {
  width: 100%;
  position: relative;
  &-bg {
    &-img {
      width: 100%;
    }
  }
  .content {
    position: absolute;
    top: 0px;
    left: 0;
    right: 0;
    margin-top: 420px;
    z-index: 20;
    .activity-rule {
      margin-top: 20px;
    }
  }
}
</style>
